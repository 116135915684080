@import 'ember-paper';

#main {
  flex-grow: 1;
}

#content-manager md-input-container {
  width: 100%;
}

md-input-container {
  width: 100%;
}
